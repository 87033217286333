import { isElectron } from '@groupthinkai/groupthink';
import { Box } from '@mui/joy';
import { useRouter } from 'next/router';
import * as React from 'react';

import DesktopSignIn from '@/components/signin/DesktopSignIn';
import { useAuth } from '@/hooks/auth';
import BaseLayout from '@/layouts/BaseLayout';

const Splash = () => {
  const router = useRouter();
  const { user, isLoading } = useAuth({ middleware: 'guest' });
  const [opacity, setOpacity] = React.useState(1);

  const isAuthenticated = !!user;

  React.useEffect(() => {
    if (!isElectron) {
      router.replace('/dashboard');
      return;
    }
  }, [router]);

  React.useEffect(() => {
    if (isLoading) return;

    if (isAuthenticated) {
      setOpacity(0);
      setTimeout(() => router.replace('/dashboard'), 300);
    }
  }, [isAuthenticated, isLoading, router]);

  if (!router.isReady) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        inset: 0,
        backgroundColor: '#1f97ac',
        opacity,
        transition: 'opacity 300ms ease-in-out',
      }}>
      {!isLoading && !isAuthenticated && <DesktopSignIn />}
    </Box>
  );
};

export default Splash;

Splash.getLayout = function getLayout(page) {
  return (
    <BaseLayout
      electronProps={{
        titleBarColor: 'transparent',
      }}>
      {page}
    </BaseLayout>
  );
};
