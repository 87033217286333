import { isElectron } from '@groupthinkai/groupthink';
import { Box, Button, Link, Stack, Typography } from '@mui/joy';
import * as React from 'react';
const DesktopSignIn = () => {
  // Clear desktop token on mount. If the frontend thinks we're signed out, we need to clear the desktop app token to keep it in sync.
  React.useEffect(() => {
    if (isElectron) {
      window.electron.sendToken(null);
    }
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        backgroundColor: 'tertiary.500',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Stack
        sx={{
          textAlign: 'center',
          WebkitUserSelect: 'none', // Prevent text selection in sign in page
        }}
        spacing={20}
        alignItems="space-between">
        <Stack spacing={4} alignItems="flex-start">
          <Typography level="h3" sx={{ color: 'white' }}>
            Welcome to Groupthink
          </Typography>
          <Typography level="body-lg" fontWeight="lg" sx={{ color: 'tertiary.100' }}>
            Please click the link below to sign in to your Groupthink account.
          </Typography>

          <Stack sx={{ textAlign: 'left' }} spacing={1} alignItems="flex-start" width="350px">
            <Button
              size="lg"
              variant="soft"
              color="neutral"
              component="a"
              target="_blank"
              fullWidth
              margin={2}
              href={
                (process.env.NEXT_PUBLIC_FRONTEND_URL ?? 'https://agendas.groupthink.com') +
                '/desktop'
              }>
              Sign In to Groupthink
            </Button>
            <Typography level="body-md" sx={{ color: 'tertiary.100' }}>
              You&apos;ll be redirected back here after signing in.
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={4} alignItems="flex-start">
          <Typography level="body-md" sx={{ color: 'tertiary.100' }}>
            Don&apos;t have an account?{' '}
            <Link
              underline="always"
              sx={{
                color: 'white',
                flexGrow: 1,
                justifyContent: 'center',
                underline: 'white',
                textDecorationColor: 'white',
              }}
              target="_blank"
              href={
                (process.env.NEXT_PUBLIC_FRONTEND_URL ?? 'https://agendas.groupthink.com') +
                '/signin?redirectTo=/desktop'
              }>
              Sign up
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default DesktopSignIn;
