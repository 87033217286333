import { useUser } from '@groupthinkai/groupthink';
import { Launch as LaunchIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, Divider, Link, Skeleton, Stack, Typography } from '@mui/joy';
import { useRouter } from 'next/router';
import * as React from 'react';

import { Modal } from '@/components/reusable';
import { useAuth } from '@/hooks/auth';
import VideoLayout from '@/layouts/VideoLayout';

const Desktop = () => {
  const router = useRouter();
  const { user, isLoading } = useUser('me');
  const [isDone, setIsDone] = React.useState(false);

  const { fetchDesktopToken } = useAuth({
    middleware: 'auth',
  });

  const [token, setToken] = React.useState(null);

  /**
   * Fetch token on mount, for speedier sign-in
   */
  React.useEffect(() => {
    const fetchToken = async () => {
      const token = await fetchDesktopToken();
      setToken(token);
    };
    fetchToken();
  }, []);

  const handleDesktopSignIn = React.useCallback(() => {
    const signIn = async () => {
      let desktopToken = token;
      if (!desktopToken) {
        desktopToken = await fetchDesktopToken();
      }
      // We use different schemes for dev and prod builds.
      // If the user does not have the app installed, this will silently fail.
      // We cannot catch the error from here, even though you may see it in the console.
      // TODO: After a delay, display a message to the user to install the app.
      window.location.href = `${
        process.env.NEXT_PUBLIC_DESKTOP_APP_SCHEME ?? 'groupthink'
      }://st?token=${desktopToken}`;
      setIsDone(true);
    };
    signIn();
  }, [token, router]);

  const handleCancel = () => {
    router.push('/dashboard');
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {isDone ? (
        <DoneModal handleCancel={handleCancel} />
      ) : (
        <Modal open={true} onClose={() => {}} variant="dialog" hideCloseButton>
          <Stack maxWidth="600px" marginX="auto" alignItems={'center'} spacing={2} marginY={2}>
            <Typography level="h4">Sign in to Groupthink desktop?</Typography>
            <Stack spacing={2}>
              <Typography level="body-lg">
                The Groupthink desktop application is requesting authorization to use your account.
              </Typography>
              <Typography level="body-lg">You are currently logged in as:</Typography>
            </Stack>
            <>
              <Avatar
                variant="soft"
                src={isLoading ? '' : user?.avatar}
                sx={{ border: '1px solid #333', width: 150, height: 150 }}>
                <Skeleton loading={isLoading} />
              </Avatar>

              <Typography
                level="body-lg"
                fontWeight="lg"
                sx={{ position: 'relative', overflow: 'hidden' }}>
                <Skeleton loading={isLoading}>{user?.name}</Skeleton>
              </Typography>

              {user?.email && <Chip>{user.email}</Chip>}
            </>
            <Box
              display="flex"
              justifyContent={'space-between'}
              width="100%"
              paddingTop={2}
              gap={2}>
              <Button fullWidth size="lg" variant="outlined" color="neutral" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                fullWidth
                size="lg"
                variant="solid"
                color="tertiary"
                endDecorator={<LaunchIcon />}
                onClick={handleDesktopSignIn}
                disabled={!token}>
                Yes, sign in
              </Button>
            </Box>
          </Stack>
        </Modal>
      )}
    </Box>
  );
};

const DoneModal = ({ handleCancel }) => {
  return (
    <Modal open={true} onClose={() => {}} variant="dialog" hideCloseButton>
      <Stack maxWidth="600px" marginX="auto" alignItems={'center'} spacing={2} marginY={2}>
        <Typography level="h4">You&apos;re all set!</Typography>
        <Divider />
        <Typography level="body-lg">
          If prompted, click{' '}
          <Typography color="neutral" fontWeight={'bold'}>
            &quot;Open Groupthink&quot;
          </Typography>{' '}
          to launch the desktop app,
        </Typography>
        <Typography level="body-lg">
          or{' '}
          <Link
            underline="hover"
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
            }}
            color="tertiary"
            onClick={handleCancel}>
            use Groupthink in your browser
          </Link>
          .
        </Typography>
      </Stack>
    </Modal>
  );
};

export default Desktop;

Desktop.getLayout = function getLayout(page) {
  return <VideoLayout>{page}</VideoLayout>;
};
